export const branch = 'branch1';
export const graphqlURI = `https://ivory.jadwal.io/graphql`;

// export const branch = 'branch3';
// export const graphqlURI = 'http://jadwal-server/graphql';

export const defaultSettings: any = {
  themeMode: 'light', // 'light' | 'dark'
  themeDirection: 'ltr', //  'rtl' | 'ltr'
  themeContrast: 'default', // 'default' | 'bold'
  themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
  themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
  themeStretch: false,
};
