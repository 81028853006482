// @mui
import Grid from '@mui/material/Unstable_Grid2';

//
import { PostItemSkeleton } from './post-skeleton';
import CategoryItem from './catergory-item';

// ----------------------------------------------------------------------

type Props = {
  categories: any[];
  loading?: boolean;
};

export default function CategoryList({ categories, loading }: Props) {
  const renderSkeleton = (
    <>
      {[...Array(16)].map((_, index) => (
        <Grid key={index} xs={12} md={6}>
          <PostItemSkeleton />
        </Grid>
      ))}
    </>
  );

  const renderList = (
    <>
      {categories.map((post) => (
        <Grid key={post._id} xs={12} sm={6} md={6}>
          <CategoryItem post={post} />
        </Grid>
      ))}
    </>
  );

  return (
    <Grid container spacing={3} sx={{ mb: 5 }}>
      {loading ? renderSkeleton : renderList}
    </Grid>
  );
}
