import { forwardRef } from 'react';
// @mui
import Link from '@mui/material/Link';
import Box, { BoxProps } from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';
import { Stack } from '@mui/material';

// ----------------------------------------------------------------------
export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const logo = (
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
        <Box component="img" alt="logo" src="/logo/wlogo.png" sx={{ width: 158, height: 45 }} />
        {/* <Typography variant="h6">Ivory Studio</Typography> */}
      </Stack>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
