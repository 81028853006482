import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { branch } from 'src/constants';
import getPortalCategories from 'src/graphql/getPortalCategories';
import getPortalItems from 'src/graphql/getPortalItems';
import getPortalSections from 'src/graphql/getPortalSections';

export function usePortal(categoryId?: string) {
  const [getCats, catData]: any = useLazyQuery(getPortalCategories, {
    variables: { branch },
  });
  const [getSects, sectData]: any = useLazyQuery(getPortalSections, {
    variables: { branch },
  });
  const [getItems, itemsData]: any = useLazyQuery(getPortalItems, {
    variables: { branch },
  });

  useEffect(() => {
    getCats();
  }, [getCats]);

  useEffect(() => {
    getSects();
  }, [getSects]);

  useEffect(() => {
    getItems();
  }, [getItems]);

  const categories = catData?.data?.getPortalCategories?.data || [];
  const sections = sectData?.data?.getPortalSections?.data || [];
  const items = itemsData?.data?.getPortalItems?.data || [];
  const loading = catData.loading || sectData.loading || itemsData.loading;

  return {
    categories,
    sections,
    items: categoryId ? items?.filter((item: any) => item?.categoryId === categoryId) : items,
    loading,
  };
}
