import { Helmet } from 'react-helmet-async';
import { CategoryDetailsHomeView } from 'src/sections/blog/view';
// sections

// ----------------------------------------------------------------------

export default function CategoryDetailsHomePage() {
  return (
    <>
      <Helmet>
        <title> category: Details</title>
      </Helmet>

      <CategoryDetailsHomeView />
    </>
  );
}
