// @mui
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// routes
import { paths } from 'src/routes/paths';
import { useParams } from 'src/routes/hook';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import { usePortal } from 'src/hooks/use-portal';
import { useMemo } from 'react';
import { Button, Card, Grid } from '@mui/material';
import Image from 'src/components/image';
import { fNumber } from 'src/utils/format-number';
import { Link } from 'react-router-dom';
import PostDetailsHero from '../post-details-hero';
import { PostDetailsSkeleton } from '../post-skeleton';

// ----------------------------------------------------------------------

export default function CategoryDetailsHomeView() {
  const params = useParams();

  const { id } = params;
  const { categories, items, loading } = usePortal(id);
  const category = useMemo(() => categories.find((cat: any) => cat._id === id), [categories, id]);
  const renderSkeleton = <PostDetailsSkeleton />;
  const renderPost = category && (
    <>
      <PostDetailsHero
        title={category.name}
        author={category.sectionName}
        coverUrl={category.headimage}
        createdAt={category.createdAt}
      />

      <Container
        maxWidth={false}
        sx={{
          py: 3,
          mb: 5,
          borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
        }}
      >
        <CustomBreadcrumbs
          links={[
            {
              name: 'Home',
              href: '/',
            },
            {
              name: 'Service',
              href: paths.category.root,
            },
            {
              name: category?.name,
            },
          ]}
          sx={{ maxWidth: 720, mx: 'auto' }}
        />
      </Container>

      <Container sx={{ mb: 5 }}>
        <Stack sx={{ maxWidth: 720, mx: 'auto' }}>
          <Typography variant="subtitle1" sx={{ mb: 5 }}>
            {category?.desc}
          </Typography>
        </Stack>
        <Grid container spacing={3}>
          {items.map((item: any) => (
            <Grid item xs={12} sm={6} md={6} key={item._id}>
              <ItemCard item={item} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );

  return <>{loading ? renderSkeleton : renderPost}</>;
}

const ItemCard = ({ item }: any) => (
  <Card sx={{ minHeight: 500, p: 2 }}>
    <Image
      alt={item?.name}
      src={item?.photo}
      sx={{
        width: 1,
        height: 250,
      }}
    />
    <Stack spacing={2} sx={{ p: 2, pb: 4 }}>
      <Typography variant="h6">{item.name}</Typography>
      <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
        {item.desc}
      </Typography>
    </Stack>
    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ p: 2 }}>
      <Typography variant="subtitle1">{fNumber(item.price)}</Typography>
      <Button component={Link} to={paths.category.checkout} variant="contained">
        Book Your Session
      </Button>
    </Stack>
  </Card>
);
