import { gql } from '@apollo/client';

export default gql`
  query getPortalSections($branch: String) {
    getPortalSections(branch: $branch) {
      ok
      error
      data {
        _id
        branch
        autoNo
        slug
        icon
        image
        headimage
        publish
        order
        additionId
        disabled
        name
        nameAr
        avatar
        color
        ispos
        desc
        userId
        createdAt
        updatedAt
      }
    }
  }
`;
