import { Navigate, useRoutes, Outlet } from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
// config
import { mainRoutes, HomePage } from './main';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <MainLayout>
          <Outlet />
        </MainLayout>
      ),
      children: [{ element: <HomePage />, index: true }],
    },
    ...mainRoutes,
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
