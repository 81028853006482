// @mui
import { Theme, SxProps } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
// components
import { useSettingsContext } from 'src/components/settings';
import { m } from 'framer-motion';
import { varHover } from 'src/components/animate';
import Iconify from 'src/components/iconify';
// ----------------------------------------------------------------------

type Props = {
  sx?: SxProps<Theme>;
};

export default function ModeButton({ sx }: Props) {
  const settings = useSettingsContext();
  const mode = settings.themeMode;

  const switchMode = () => {
    const newMode = mode === 'light' ? 'dark' : 'light';
    settings.onUpdate('themeMode', newMode);
  };
  const solarIcon = mode === 'light' ? 'solar:moon-bold' : 'solar:sun-2-bold';
  return (
    <Box>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        aria-label="mode"
        onClick={switchMode}
        sx={{
          width: 40,
          height: 40,
        }}
      >
        <Iconify icon={solarIcon} width={24} />
        {/* <SvgColor src={icon} /> */}
      </IconButton>
    </Box>
  );
}
