import { gql } from '@apollo/client';

export default gql`
  query getPortalItems($branch: String) {
    getPortalItems(branch: $branch) {
      ok
      message
      error
      data {
        _id
        branch
        autoNo
        slug
        itemType
        expenseType
        barcode
        disabled
        additionId
        publish

        name
        nameAr
        desc
        descAr

        sectionId
        sectionName
        sectionNameAr
        sectionSlug
        sectionOrder

        categoryId
        categoryName
        categoryNameAr
        categorySlug
        categoryOrder

        collecs

        brandId
        brandName
        brandNameAr

        qty
        cost
        price
        unit
        size
        weight
        color
        photo
        photoAr
        photos
        salesCurve

        createdAt
        updatedAt
      }
    }
  }
`;
