// @mui
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
// routes
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// assets
import { AvatarShape } from 'src/assets/illustrations';
// components
import Image from 'src/components/image';
import TextMaxLine from 'src/components/text-max-line';
import { useLocales } from 'src/locales';
import { Typography } from '@mui/material';
import { useState } from 'react';

// ----------------------------------------------------------------------

type Props = {
  post: any;
  index?: number;
};

export default function CategoryItem({ post, index }: Props) {
  const theme = useTheme();
  const [overlay, setOverlay] = useState(alpha(theme.palette.grey[900], 0.3));
  const [isHovered, setIsHovered] = useState(false);

  const mdUp = useResponsive('up', 'md');

  const { isRTL } = useLocales();

  const { headimage, name, nameAr, _id } = post;
  const title = isRTL ? nameAr : name;

  const handleMouseEnter = () => {
    setOverlay(alpha(theme.palette.grey[900], 0.5)); // Change to desired hover overlay color
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setOverlay(alpha(theme.palette.grey[900], 0.3)); // Change back to original overlay color
    setIsHovered(false);
  };

  if (mdUp) {
    return (
      <Card
        sx={{ position: 'relative', cursor: 'pointer' }}
        onClick={() => {
          window.location.href = paths.category.details(_id);
        }}
      >
        <Image
          alt={title}
          src={headimage}
          overlay={overlay}
          sx={{
            width: 1,
            height: 360,
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
        <Box
          sx={{
            left: 40,
            zIndex: 9,
            bottom: 20,
            position: 'absolute',
            transform: isHovered ? 'scale(1.3)' : 'scale(1)',
            transition: 'transform 0.3s ease',
          }}
        >
          <Typography
            variant="overline"
            sx={{
              px: 1,
              color: 'common.white',
              fontSize: 24,
            }}
          >
            {title}
          </Typography>
          {/* <PostContent title={title} index={index} /> */}
        </Box>
      </Card>
    );
  }

  return (
    <Card
      onClick={() => {
        window.location.href = paths.category.details(_id);
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <AvatarShape
          sx={{
            left: 0,
            zIndex: 9,
            width: 88,
            height: 36,
            bottom: -16,
            position: 'absolute',
          }}
        />

        <Image alt={title} src={headimage} ratio="4/3" />
      </Box>

      <PostContent title={title} id={_id} />
    </Card>
  );
}

// ----------------------------------------------------------------------

type PostContentProps = {
  title: string;
  index?: number;
  id: string;
};

export function PostContent({ title, index, id }: PostContentProps) {
  const linkTo = paths.category.details(id);

  const latestPostLarge = index === 0;

  const latestPostSmall = index === 1 || index === 2;

  return (
    <CardContent
      sx={{
        pt: 6,
        width: 1,
        ...((latestPostLarge || latestPostSmall) && {
          pt: 0,
          zIndex: 9,
          bottom: 0,
          position: 'absolute',
          color: 'common.white',
        }),
      }}
    >
      <Link color="inherit" component={RouterLink} href={linkTo}>
        <TextMaxLine variant="h5" line={2} persistent>
          {title}
        </TextMaxLine>
      </Link>
    </CardContent>
  );
}
