import { Helmet } from 'react-helmet-async';
// sections
import { CategoryListHomeView } from 'src/sections/blog/view';

// ----------------------------------------------------------------------

export default function CategoryListHomePage() {
  return (
    <>
      <Helmet>
        <title> Category: List</title>
      </Helmet>

      <CategoryListHomeView />
    </>
  );
}
