import { gql } from '@apollo/client';

export default gql`
  query getPortalCategories($branch: String) {
    getPortalCategories(branch: $branch) {
      ok
      error
      data {
        _id
        branch
        autoNo
        additionId
        catType
        disabled
        name
        nameAr
        avatar
        color
        ispos

        slug
        icon
        image
        headimage
        publish
        order

        sectionId
        sectionName
        sectionNameAr
        sectionSlug
        sectionOrder
        note

        desc
        userId
        createdAt
        updatedAt
      }
    }
  }
`;
