// @mui
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// components
import { useSettingsContext } from 'src/components/settings';
//
import { usePortal } from 'src/hooks/use-portal';
import { Stack } from '@mui/material';
import { useResponsive } from 'src/hooks/use-responsive';
import CategoryList from '../category-list';

// ----------------------------------------------------------------------

export default function CategoryListHomeView() {
  const settings = useSettingsContext();
  const { categories, loading } = usePortal();
  const mdUp = useResponsive('up', 'md');

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <Stack spacing={3} sx={{ textAlign: 'center', my: 5 }}>
        <Typography variant="h2">Our Services</Typography>
        <Typography sx={{ fontSize: mdUp ? 24 : 16, width: '60%', textAlign: 'left' }}>
          Whether you’re celebrating motherhood, a birthday, or any milestone, we’re here to create
          memories you’ll treasure forever.
        </Typography>
      </Stack>

      <CategoryList categories={categories} loading={loading} />
    </Container>
  );
}
